/* Tooltip container */
.Basic-tooltip {
  border-bottom: 1px dotted var(--accent-color); /* If you want dots under the hoverable text */
}
.Theme-dark .Basic-tooltip {
  border-bottom: 1px dotted var(--white); /* If you want dots under the hoverable text */
}

.Basic-tooltip-light .tooltip-inner {
  background-color: var(--accent-color);
  color: var(--white);
}

.Basic-tooltip-light.bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow::before {
  border-top-color: var(--accent-color);
}
.Basic-tooltip-light.bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow::before {
  border-right-color: var(--accent-color);
}
.Basic-tooltip-light.bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow::before {
  border-left-color: var(--accent-color);
}
.Basic-tooltip-light.bs-tooltip-auto[data-popper-placement^="bottom"]
  .tooltip-arrow::before {
  border-bottom-color: var(--accent-color);
}

.Basic-tooltip-dark .tooltip-inner {
  background-color: var(--white);
  color: var(--accent-color);
}
.Basic-tooltip-dark.bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow::before {
  border-top-color: var(--white);
}
.Basic-tooltip-dark.bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow::before {
  border-right-color: var(--white);
}

.Basic-tooltip-dark.bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow::before {
  border-left-color: var(--white);
}
.Basic-tooltip-dark.bs-tooltip-auto[data-popper-placement^="bottom"]
  .tooltip-arrow::before {
  border-bottom-color: var(--white);
}

.Basic-tooltip-md {
  font-size: 1.1rem;
}
