.Button {
  border-radius: 3px;
  width: 100%;
}
.Button-w-auto {
  width: auto;
}
.Button.Button-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white);
}
.Button.Button-secondary, .Button.Button-primary:hover {
  background-color: var(--white);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

