.Separator {
  border-color: var(--text-color);
}
.aei-shaded-bg .Separator {
  border-color: var(--text-accent-color);
  border-style: solid;
  opacity: 1;
}

.Separator-tickness-1 {
  border-top-width: 1px;
}

.Separator-tickness-2 {
  border-top-width: 2px;
}
.Separator-tickness-3 {
  border-top-width: 4px;
}
.shadow {
  height: 1px;
  border-top-color:  #1f1209;
  box-shadow: 0 2px 3px 0px #333;
}

.primary {
  border-color: var(--primary-color)!important;
  border-style: solid;
  opacity: 1;
}
