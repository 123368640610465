.form-switch .form-check-input.AEI-toggle {
  scale: 1.2;
  margin-right: 15px;
}
.Theme-light .AEI-toggle.form-check-input:checked {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}
.Theme-light .AEI-toggle.form-check-input:focus {
  border-color: var(--accent-color);
  outline: 0;
  box-shadow: 0 0 0 0 var(--accent-color);
}
.Theme-light .form-switch .AEI-toggle.form-check-input:focus:not(:checked) {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

.Theme-dark .AEI-toggle.form-check-input:checked {
  background-color: var(--white);
  border-color: var(--white);
}
.Theme-dark .AEI-toggle.form-check-input:focus {
  border-color: var(--accent-color);
  outline: 0;
  box-shadow: 0 0 0 0 var(--accent-color);
}
.Theme-dark .form-switch .AEI-toggle.form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%1, 1, 37, 1%29%27/%3e%3c/svg%3e");
}
.Theme-dark .form-switch .AEI-toggle.form-check-input:focus:not(:checked) {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}
