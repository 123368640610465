.Pagination-themed .page-link {
  color: var(--accent-color);
  background-color: var(--white);
  border-color: var(--accent-color);
}
.Pagination-themed .page-link.disabled {
  color: var(--dark-gray);
}
.Pagination-themed .page-link.active {
  color: var(--white);
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}
.Pagination-themed .page-link:focus {
  box-shadow: none;
}

.Theme-dark .Pagination-themed .page-link {
  color: var(--accent-color);
  background-color: var(--white);
  border-color: var(--accent-color);
}
.Theme-dark .Pagination-themed .page-link.active {
    color: var(--white);
    background-color: var(--accent-color);
    border-color: var(--accent-color);
  }
