.Theme-dark .Badge-primary {
  background-color: var(--white);
  color: var(--accent-color);
}
.Badge-primary {
  background-color: var(--accent-color);
  color: var(--text-accent-color);
}
.Badge-added{
    background-color: #24b519;
    color: var(--text-accent-color);
}
.Badge-removed{
    background-color: #ab1b1b;
    color: var(--text-accent-color);
}
