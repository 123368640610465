.btn.btn-outline-accent {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #ced4da;
  color: var(--accent-color);
  background-color: #fff;
}

.btn.btn-outline-accent:hover {
    color: #fff ;
    background-color: var(--accent-color);
  }