:root {
  --black: #333;
  --dark-gray: #6c757d;
  --white: #f1f4f8;
  --primary-color: #c2092b;
  --accent-color: #010125;
  --accent-color-sd-1: #141530;
  --accent-color-sd-2: #242843;
  --text-color: #fff;
  --pure-white: #fff;
  --background-color: var(--accent-color);
  --font-family: "Nunito Sans", sans-serif;
}

.Theme-dark {
  --text-color: var(--white);
  --text-accent-color: var(--white);
  --background-color: var(--accent-color);
  --background-color-shade: var(--accent-color-sd-1);
  --secondary-button-background-color: var(--primary-color);
  transition: color, background-color 0.4s ease-in-out;
}

.Theme-light {
  --background-color: var(--pure-white);
  --background-color-shade: var(--accent-color-sd-1);
  --text-color: var(--accent-color);
  --text-accent-color: var(--white);
  --secondary-button-background-color: var(--accent-color);
}

.card-body.card-body-margin-reset {
  margin-left: -4px;
  margin-right: -4px;
}
.Theme-dark .card-themed {
  background-color: var(--accent-color-sd-1) !important;
  border-color: var(--dark-gray) !important;
  color: var(--text-color) !important;
}
* {
  font-family: var(--font-family), sans-serif;
  transition: color, background-color 0.4s ease-in-out;
}

.Theme-light .border-dark,
.Theme-light .border-bottom-themed {
  border-color: var(--accent-color) !important;
}

.Theme-dark .border-dark,
.Theme-dark .border-bottom-themed {
  border-color: var(--dark-gray) !important;
}
.Theme-dark .text-muted {
  color: var(--dark-gray) !important;
}

.Theme-dark .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--accent-color-sd-2);
  color: var(--text-color);
}
.Theme-dark .table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: var(--accent-color-sd-1);
  color: var(--text-color);
}

.Theme-light .aei-spinner-color {
  color: var(--accent-color) !important;
}
.Theme-dark .aei-spinner-color {
  color: var(--dark-gray) !important;
}
.aei-h6 {
  color: var(--primary-color);
}

.aei-shaded-bg {
  background-color: var(--background-color-shade);
  color: var(--text-accent-color);
}
.aei-shaded-bg table,
.aei-shaded-bg table tr,
.aei-shaded-bg table tr td,
.aei-shaded-bg table tr th,
.aei-shaded-bg p,
.aei-shaded-bg h1,
.aei-shaded-bg h2,
.aei-shaded-bg h3,
.aei-shaded-bg h4,
.aei-shaded-bg h5,
.aei-shaded-bg h6 {
  background-color: var(--background-color-shade);
  color: var(--text-accent-color);
}

.aei-primary-link,
.aei-primary-link:is(:hover, :focus, :visited, :active) {
  color: var(--primary-color);
  text-decoration: none;
}

p {
  line-height: 1.6em;
}
.full-width-container {
  margin: 0 -12px;
}
.text-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.collapsing.no-animation {
  -webkit-transition: none !important;
  transition: none !important;
  display: none !important;
}

.max-width-container {
  max-width: 1900px;
  margin: 0 auto;
}

.main-container {
  background-color: var(--background-color);
  color: var(--text-color);
}

table,
tr,
td,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}


.modal-open {
  overflow: hidden;
  padding-right: 0px;
}
.text-price,
.text-alert {
  /* color: #d68d24!important; */
  color: var(--primary-color) !important;
}

@media screen and (max-width: 1100px) {
  .table-md-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.Theme-dark .alert-danger {
  --bs-alert-color: #ea868f;
  --bs-alert-bg: #2c0b0e;
  --bs-alert-border-color: #842029;
  --bs-alert-link-color: #ea868f;
}
.Theme-dark .alert-warning p {
  color: var(--black) !important;
}

.Theme-dark .alert-primary {
  --bs-alert-bg: var(--accent-color);
  --bs-alert-border-color: var(--accent-color-sd-2);
}

.react-datepicker-popper {
  z-index: 2000 !important;
}
.with-sidebar {
  margin-left: 260px;
  padding-top: 50px;
  padding-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .with-sidebar {
    margin-left: 0px;
  }
}

.stripped-rows > .row:nth-child(odd) {
  background-color: #f2f2f2;
  border-bottom: 1px solid var(--bs-border-color-translucent);
  border-top: 1px solid var(--bs-border-color-translucent);
}
.Theme-dark .stripped-rows > .row:nth-child(odd) {
  background-color: var(--accent-color-sd-2);
  border-color: var(--dark-gray) !important;
}

.table > :not(caption) > * > * {
  color: inherit;
  background-color: inherit;
  /* border-bottom-width:inherit; */
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.Button-semi-rounded {
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  z-index: 10;
  position: relative;
}
