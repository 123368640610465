.FixedFooter-container {
  border-top: 2px solid var(--primary-color);
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 85px;
  background: var(--accent-color);
  color: var(--text-color);
  z-index: 100;
  padding: 35px 0px;
}
