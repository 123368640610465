.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.spinner-container .spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  /* width: var(--bs-spinner-width); */
  /* height: var(--bs-spinner-height); */
  vertical-align: -0.125em;
  border-radius: 50%;
  border: 3px solid;
}

.Theme-dark .spinner-container .spinner.spinner-slow,
.Theme-dark .spinner-container .spinner.spinner-slower  {
  border-color: var(--white);
  border-top-color: transparent;
}

.spinner-container .spinner.spinner-slower {
  border-color: var(--accent-color);
  border-top-color: transparent;
  animation: spinning 1s linear infinite;
}

.spinner-container .spinner.spinner-slow {
  border-color: var(--accent-color);
  border-top-color: transparent;
  animation: spinning 1.5s linear infinite;
}

.spinner-container .spinner.spinner-fast {
  border-color: var(--primary-color);
  border-left-color: transparent;
  animation: spinning 1s linear infinite reverse;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
